<template>
  <v-navigation-drawer
      app
      v-model="$store.state.menuNav"
      temporary
      touchless
  >
    <v-list nav>
      <v-list-item>
        <v-list-item-avatar rounded width="60" height="60">
          <v-img
              v-if="!this.$vuetify.theme.dark"
              src="@/assets/logo_light.png"
              contain
          />
          <v-img
              v-if="this.$vuetify.theme.dark"
              src="@/assets/logo_dark.png"
              contain
          />
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-subtitle>문호교회 2025년 표어</v-list-item-subtitle>
          <v-list-item-title>
            예수 사랑을 문호리에서 땅끝까지
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>


    <template>
      <v-list dense>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title
              v-on:click="movePage('/')"
              class="font-custom-menu"
          >
            Home
          </v-list-item-title>
        </v-list-item>

        <v-list-group
            v-for="(k, idx1) in propsmenu"
            :key=idx1
            no-action
            v-show="!k.meta.requiresAuth || $store.state.moduleSession.session.user"
        >
          <v-icon slot="prependIcon">{{ k.icon }}</v-icon>
          <template v-slot:activator>
            <v-list-item-title class="font-custom-menu">{{ k.title }}</v-list-item-title>
          </template>

          <v-list-item
              v-for="(y, idx2) in k.subtitles"
              :key=idx2
              link
              v-on:click="movePage(y.link)"
              v-bind:target="y.target"
          >
            <v-list-item-content class="ma-0 pa-0">
              <v-list-item-title class="font-custom-menu my-2">
                {{ y.title }}
                <v-icon right v-if="y.target" x-small>
                  mdi-open-in-new
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavMenu.vue",

  props: ['propsmenu'],

  methods: {
    movePage (url) {
      this.$router.push({ path: url}).catch(()=>{})
    }
  }
}
</script>

<style scoped>
* {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 700;
  letter-spacing : -0.06em;
}
</style>