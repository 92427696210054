<template>
  <v-main app>
    <Subtitle>섬기는 사람들</Subtitle>

    <v-card dense no-gutters tile
            elevation="0"
            class="my-10"
        v-for="(member, idx) in people"
        v-bind:key="idx"
    >

<!--      <v-subheader>-->
<!--        {{ member.title }}-->
<!--      </v-subheader>-->
      <v-card-title>
        {{ member.title }}
      </v-card-title>
      <v-divider></v-divider>

      <v-row dense no-gutters class="d-flex flex-column flex-sm-row">

        <v-col
            cols="12"
            md="6"
            lg="4"
            xl="3"
            v-for="(person, index) in member.members"
            v-bind:key="index"
        >
          <v-card
              class="mx-5 my-5"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title>{{ person.name }}</v-card-title>
                <v-card-subtitle>{{ person.position }}</v-card-subtitle>
              </div>

              <v-avatar
                  class="ma-3 pa-3"
                  size="125"
                  tile
                  v-if="person.image"
              >
                <v-img v-bind:src="person.image" cover></v-img>
              </v-avatar>
            </div>

            <v-card-text>
              <v-row
                  v-for="(message, index) in person.messages"
                  :key="index"
                  align="center"
              >
                <div class="grey--text mx-3 mb-5">
                  {{ message }}
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


    </v-card>



  </v-main>
</template>

<script>
import Subtitle from "@/components/Subtitle"

export default {
  name: "People",

  components: { Subtitle },

  data () {
    return {
      people: [
        {
          'title': '교역자',
          'members': [
            {
              'position': '위임목사',
              'name': '전창근',
              'messages': [
                '전창근 목사님은 대학에서 법학을 전공한 후 장로회신학대학교 신학대학원(M.Div.)을 졸업하고 미국 시카고 소재 McCormick Theological Seminary에서 석사(MATS)와 영성을 전공으로 목회학 박사학위(D.Min.)를 받았습니다. 깊은 영성으로 세상을 아름답게 하는 문호공동체를 꿈꾸며 교회를 섬기고 있습니다.'
              ],
              'image': require('@/assets/people/people-04.png')
            },
            {
              'position': '협동목사',
              'name': '한국일',
              'messages': [
                '한국일 목사님은 장로회신학대학교, 대학원을 졸업하시고 독일 하이델베르그 대학에서 박사학위를 받으시고 현재 장로회신학대학교 선교학 은퇴교수이십니다. 마을목회로 세상을 아름답게 하는 일에 지금도 열정을 다해 섬기고 계십니다.'
              ],
              'image': require('@/assets/people/people-01.png')
            },
            {
              'position': '부목사',
              'name': '노성광',
              'messages': [
                '노성광 목사는 백석대학교 신학대학원(M.Div.)을 졸업하고, 서울장신대학교 신학대학원을 수료하였습니다. 현재 교구와 행정, 청년부를 담당하고 있습니다.'
              ],
              'image': require('@/assets/people/people-03.png')
            },
            {
              'position': '부목사',
              'name': '최성열',
              'messages': [
                '최성열 목사는 부산장신대학교 신학대학원(M.Div.)을 졸업하였습니다. 현재 교구와 청소년부를 담당하고 있습니다.'
              ],
              'image': require('@/assets/people/people-05.png')
            },
            {
              'position': '교육목사',
              'name': '이미숙',
              'messages': [
                '이미숙 목사는 장로회신학대학원과 동 대학원에서 구약학을 전공하였습니다. 세살버릇 여든까지가 아니라 세살 신앙 여든까지 가도록 유치/유년부 어린이들의 신앙교육에 힘을 쏟고 있습니다.'
              ],
              'image': require('@/assets/people/people-10.png')
            },
            {
              'position': '교육목사',
              'name': '서승조',
              'messages': [
                '서승조 목사는 아세아연합신학대학원을 졸업하고 동 대학원에서 목회학을 전공했습니다. 1부 호산나 성가대 지휘자로, 시니어 대학부 담당 및 남여 중창단 지도목사로 섬기고 있습니다.'
              ],
              'image': require('@/assets/people/people-11.png')
            },
            // {
            //   'position': '교육목사',
            //   'name': '김찬익',
            //   'messages': [
            //     '김찬익 목사는 장로회신학대학교 신학대학원(M.Div)를 졸업하였습니다. 현재 방송부와 청년부를 담당하고 있습니다.'
            //   ],
            //   'image': require('@/assets/people/people-12.png')
            // },
            {
              'position': '교육목사',
              'name': '김해연',
              'messages': [
                '김해연 목사는 장로회 신학대학원(M.div)을 졸업하고 새가족부, 금요여성기도회, 시니어부 식사담당, 100일 성경통독을 섬기고 있습니다.'
              ],
              'image': require('@/assets/people/people-09.png')
            },
            {
              'position': '교육전도사',
              'name': '이석준',
              'messages': [
                '이석준 교육전도사는 서울신학대학교 신학과를 졸업하였습니다. 현재 아동부를 담당하고 있습니다.'
              ],
              'image': require('@/assets/people/people-13.png')
            }
          ]
        },




        {
          'title': '은퇴장로',
          'members': [
            {
              'position': '장로',
              'name': '신용균',
              'image': require('@/assets/people/people-20.png')
            },
            {
              'position': '장로',
              'name': '한명수',
              'image': require('@/assets/people/people-21.png')
            },
            {
              'position': '장로',
              'name': '박기화',
              'image': require('@/assets/people/people-22.png')
            },
            {
              'position': '장로',
              'name': '임정복',
              'image': require('@/assets/people/people-23.png')
            },
            {
              'position': '장로',
              'name': '김중구',
              'image': require('@/assets/people/people-25.png')
            },
            {
              'position': '장로',
              'name': '이성종',
              'image': require('@/assets/people/people-26.png')
            },
            {
              'position': '장로',
              'name': '유명상',
              'image': require('@/assets/people/people-30.png')
            },
          ]
        },



        {
          'title': '시무장로',
          'members': [
            {
              'position': '장로',
              'name': '박현문',
              'image': require('@/assets/people/people-31.png')
            },
            {
              'position': '장로',
              'name': '오동훈',
              'image': require('@/assets/people/people-32.png')
            },
            {
              'position': '장로',
              'name': '손창현',
              'image': require('@/assets/people/people-33.png')
            },
            {
              'position': '장로',
              'name': '김동순',
              'image': require('@/assets/people/people-34.png')
            },
            {
              'position': '장로',
              'name': '김형돈',
              'image': require('@/assets/people/people-35.png')
            },
            {
              'position': '장로',
              'name': '박주근',
              'image': require('@/assets/people/people-36.png')
            },
            {
              'position': '장로',
              'name': '강천수',
              'image': require('@/assets/people/people-37.png')
            },
            {
              'position': '장로',
              'name': '엄두옥',
              'image': require('@/assets/people/people-38.png')
            }
          ]
        },






        // {
        //   'title': '협동장로',
        //   'members': [
        //     {
        //       'position': '장로',
        //       'name': '장석규',
        //       'image': require('@/assets/people/people-50.png')
        //     }
        //   ]
        // }




      ]
    }
  }
}
</script>

<style scoped>

</style>